import './Main.css';
import * as React from 'react';

const logo = require('./assets/loading.gif');
class Main extends React.Component {

  constructor(props, context) {
    super(props, context);

    this.state = {

    };
}

render() {
  return (
    <>
    <div id="loading_indicator" className="container">
      <img style={{width: this.state.width < 1000? "40vw":"15vw"}} src={logo} alt="loading" />
    </div>
  </>
  );
  }
}

export default Main;
